@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Source+Code+Pro:wght@400;700&display=swap');

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "./abstracts/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/bootstrap";

// buttons

button, .btn {
    font-family: 'Source Code Pro', monospace;
}

// Buttons shadows
.btn-primary-shadow {
    box-shadow: 3px 3px  rgba($primary, .5);
    text-shadow: 1px 1px  rgba($white, .5);
}
.btn-secondary-shadow {
    box-shadow: 3px 3px  rgba($secondary, .5);
    text-shadow: 1px 1px  rgba($white, .5);
}
.btn-quasr-shadow {
    box-shadow: 3px 3px  rgba($quasr, .5);
    text-shadow: 1px 1px  rgba($white, .5);
}
.btn-success-shadow {
    box-shadow: 3px 3px  rgba($success, .5);
    text-shadow: 1px 1px  rgba($white, .5);
}
.btn-white-shadow {
    box-shadow: 3px 3px  rgba($white, .5);
    text-shadow: 1px 1px  rgba($white, .5);
}


// Single box shadows
.box-primary-shadow {
    box-shadow: 3px 3px  rgba($primary, .5);
}
.box-success-shadow {
    box-shadow: 3px 3px  rgba($success, .5);
}
.box-quasr-shadow {
    box-shadow: 3px 3px  rgba($quasr, .5);
}
.box-white-shadow {
    box-shadow: 3px 3px  rgba($white, .5);
}

// Text shadows
.text-primary-shadow {
    text-shadow: 1px 1px  rgba($primary, .5);
}
.text-secondary-shadow {
    text-shadow: 1px 1px  rgba($secondary, .5);
}
.text-success-shadow {
    text-shadow: 1px 1px  rgba($success, .5);
}
.text-white-shadow {
    text-shadow: 1px 1px rgba($white, .5);
}

// line effect
.c-line-quasr, .c-line-primary, .c-line-secondary, .c-line-success, .c-line-black, .c-line-white {
    position: relative;
}
.c-line-quasr::after {
    content: '';
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:1rem;
    display: block;
    width: 4rem;
    height: 0.5rem;
    background-color: rgba($quasr, .5) ;
}
.c-line-primary::after {
    content: '';
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:1rem;
    display: block;
    width: 4rem;
    height: 0.5rem;
    background-color: rgba($primary, .5) ;
}
.c-line-secondary::after {
    content: '';
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:1rem;
    display: block;
    width: 4rem;
    height: 0.5rem;
    background-color: rgba($secondary, .5) ;
}
.c-line-success::after {
    content: '';
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:1rem;
    display: block;
    width: 4rem;
    height: 0.5rem;
    background-color: rgba($success, .5) ;
}
.c-line-white::after {
    content: '';
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:1rem;
    display: block;
    width: 4rem;
    height: 0.5rem;
    background-color: rgba($white, .5) ;
}
.c-line-black::after {
    content: '';
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:1rem;
    display: block;
    width: 4rem;
    height: 0.5rem;
    background-color: rgba($dark, .5) ;
}


// Labels
.label-quasr {
    font-family: 'Source Code Pro', monospace;
    color: rgba($quasr, 1);
    font-weight: bold;
    font-size: 1.5rem;
}
.label-primary {
    font-family: 'Source Code Pro', monospace;
    color: rgba($primary, 1);
    font-weight: bold;
    font-size: 1.5rem;
}
.label-success {
    font-family: 'Source Code Pro', monospace;
    color: rgba($success, 1);
    font-weight: bold;
    font-size: 1.5rem;
}
.label-secondary {
    font-family: 'Source Code Pro', monospace;
    color: rgba($secondary, 1);
    font-weight: bold;
    font-size: 1.5rem;
}
.label-white {
    font-family: 'Source Code Pro', monospace;
    color: rgba($white, 1);
    font-weight: bold;
    font-size: 1.5rem;
}
.label-sm {
    font-size: 1.125rem;
}

// Tabls
.tabs > button {
    font-family: $input-btn-font-family;
    color: rgba($primary, 0.5);
    font-weight: bold;
    border: unset;
    background-color: unset;
    border-bottom: rgba($primary, 0.5) solid 0.25rem;
    margin: 0 1rem 1rem 0;
}
.tabs > button:hover {
    border-bottom: rgba($primary, 1) solid 0.25rem;
}
.tabs > button.active {
    color: rgba($primary, 1);
    border-bottom: rgba($primary, 1) solid 0.25rem;
}

// Icons
.icon-2 {
    width: 2rem;
}
.icon-3 {
    width: 3rem;
}
.icon-white-shadow {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%) drop-shadow( 2px 2px rgba($white, .25));
}
.icon-black-shadow {
    filter: invert(0%) sepia(2%) saturate(4%) hue-rotate(127deg) brightness(92%) contrast(100%) drop-shadow( 2px 2px rgba($black, .25));
}

// Navbar
ul.navbar-nav > li 
{  
    padding-left: 1rem; 
    padding-right: 1rem;
}

ul.navbar-nav > li > a
{  
    font-weight: bold;
    color: rgba($dark, 1)
}

// Section backgrounds by id
#announcement {
    background:
        linear-gradient(217deg, rgba($success,0.9), rgba($quasr,0.9) 70.71%),
        linear-gradient(127deg, rgba($secondary,0.9), rgba($success,0.9) 70.71%),
        linear-gradient(336deg, rgba($quasr,0.9), rgba($secondary,0.9) 70.71%);
}

#banner {
    background-image: url(../images/banner.jpg);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 35vw;
}


@media (max-width: 991.98px) {
#banner {
    background-image: linear-gradient(174deg, var(--bs-quasr-light) 85%, #ffffff calc(85% + 1px))
}
}
#about {
    background-image: linear-gradient(176deg, #ffffff 45%, var(--bs-success-light) calc(45% + 1px), var(--bs-success-light) 90%, #122733 calc(90% + 1px))
}  

// Footer

footer a, footer a:hover {
    color: var(--bs-quasr-light);
    text-decoration: none;
}

footer a:hover {
    color: var(--bs-quasr-alt);
}


// Hubspot Form

label span{
font-family: var(--bs-body-font-family);
font-size: var(--bs-body-font-size);
font-weight: var(--bs-body-font-weight);
line-height: var(--bs-body-line-height);
text-align: var(--bs-body-text-align);
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: #0000;
--bs-gutter-x: 3rem;
--bs-gutter-y: 3rem;
--bs-text-opacity: 1;
color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
--bs-bg-opacity: 1;
box-sizing: border-box;
display: inline-block;
}


// Tab-image

.tab-image{
    max-height: 367px;
}

// video
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
}
iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}