
// Colors
$quasr: #3C78D8;
$quasr-alt: #9FBCEC;
$quasr-light: #D7E3F7;

$primary: #9860E7;
$primary-alt: #CCB1F3;
$primary-light: #F0E8FC;

$secondary: #FD5653;
$secondary-alt: #FEACAA;
$secondary-light: #FFE7E6;

$success: #4BC9AC;
$success-alt: #A5E4D5;
$success-light: #E4F7F3;

$dark: #122733;
$light: #F7F7F8;

$black: #000000;
$white: #ffffff;

$theme-colors: (
  "quasr": $quasr,
  "quasr-alt": $quasr-alt,
  "quasr-light": $quasr-light,
  "primary": $primary,
  "primary-alt": $primary-alt,
  "primary-light": $primary-light,
  "secondary": $secondary,
  "secondary-alt": $secondary-alt,
  "secondary-light": $secondary-light,
  "success": $success,
  "success-alt": $success-alt,
  "success-light": $success-light,
  "dark": $dark,
  "light": $light,
  "black": $dark,
  "white": $white
);

$min-contrast-ratio: 1;

// Typography
$font-family-base: 'Noto Sans', sans-serif;
$font-weight-base: 300;

// Buttons
$btn-font-weight: 'bold';

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 10.5
);
